<template>
  <div class="STERiJECT" style="width: 80%; margin: 0 auto;">
    <div class="container">
      <img src="@/assets/images/product/STERiJECT/product_ject.jpg" class="img-fluid" />
    </div>
    <div class="container mt-5">
      <div class="row">
        <!-- 許可證圖片 -->
        <div class="col-md-4 col-12 custom-col-md-4">
          <img src="@/assets/images/product/STERiJECT/product_ject_2.png" alt="左侧图片" class="img-fluid" />
        </div>
        <!-- 標題、文本和下方產品圖片 -->
        <div class="col-md-6 mx-auto text-start d-flex flex-column align-items-start">
          <h5 class="text-primary align-self-start">
            <p v-html="$t('STERiJECT.title')"></p>
          </h5>
          <!-- 文本 -->
          <div class="text-dark-gray" style="max-height: 200px; overflow-y: auto;">
            <p v-html="$t('STERiJECT.description')"></p>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增表格部分 -->
    <div class="table-container">
      <div class="container mt-5" style="width: 80%; margin: 0 auto; background-color: #f0f0f0; padding: 20px;">
        <div style="width: 80%; margin: 0 auto; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
          <span class="text-primary" style="font-weight: bold;">
            <p v-html="$t('model')"></p>
          </span>
          <span style="font-style: italic; color: #000;">*TSKiD™ = 薄壁技術</span>
        </div>
        <div style="overflow-x: auto;">
          <table class="table custom-table" style="width: 80%; margin: 0 auto;">
            <thead>
              <tr class="header-row">
                <th scope="col">Gauge</th>
                <th scope="col">Code</th>
                <th scope="col">Description</th>
                <th scope="col">Inch</th>
                <th scope="col">*TSKiD™</th>
                <th scope="col">Box</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(group, groupIndex) in groupedData" :key="groupIndex">
                <tr v-for="(item, itemIndex) in group.items" :key="item.code" :class="getRowClass(displayIndex(groupIndex, itemIndex))">
                  <td v-if="itemIndex === 0" :rowspan="group.items.length" class="gauge-cell">{{ group.gauge }}</td>
                  <td>{{ item.code }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.inch }}</td>
                  <td>{{ item.utw }}</td>
                  <td>{{ item.box }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- 聯絡我們 -->
    <div id="contact" class="bg-primary">
      <div class="container py-5 text-start text-white col">
        <p class="h4 fw-bold">遠京企業股份有限公司 MYGUARD CO., LTD.<br></p>
        <p class="mt-3">臺灣Taiwan</p>
        <p>地址：臺灣桃園市龜山區文興路146號3樓之2</p>
        <p>3F.-2, No.146, Wenxing Rd.,Guishan, Taoyuan City 33377, Taiwan</p>
        <p>電話: <a href="tel:+886-3327-0080" style="color: white;">+886 3 327-0080</a></p>
        <p>Email: <a href="mailto: info@myguard.com" style="color: white;">info@myguard.com</a></p>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        { gauge: '34G', code: 'LDS-02009', description: 'THE INViSIBLE NEEDLE 0.2 x 9mm', inch: '⅜″', utw: '', box: '100' },
        { gauge: '33G', code: 'PRE-33004', description: 'Regular Hub 33G x 4mm', inch: '³⁄₁₆″', utw: '', box: '100' },
        { gauge: '33G', code: 'PRE-33013', description: 'Regular Hub 33G x 13mm', inch: '½″', utw: '', box: '100' },
        { gauge: '32G', code: 'PRE-32004', description: 'Regular Hub 32G x 4mm', inch: '³⁄₁₆″', utw: '', box: '100' },
        { gauge: '32G', code: 'PRE-32009', description: 'Regular Hub 32G x 9mm', inch: '⅜″', utw: '', box: '100' },
        { gauge: '32G', code: 'PRE-32013', description: 'Regular Hub 32G x 13mm', inch: '½″', utw: '', box: '100' },
        { gauge: '31G', code: 'HPC-31013E', description: 'Advanced Hub 31G x 13mm', inch: '½″', utw: '√', box: '100' },
        { gauge: '30G', code: 'PRE-30004I', description: 'Regular Hub 30G x 4mm', inch: '³⁄₁₆″', utw: '√', box: '100' },
        { gauge: '30G', code: 'LDS-30009I', description: 'Low Dead Space Hub 30G x 9mm', inch: '⅜”', utw: '√', box: '100' },
        { gauge: '30G', code: 'HPC-30013I', description: 'Advanced Hub 30G x 13mm', inch: '½”', utw: '√', box: '100' },
        { gauge: '27G', code: 'HPC-27013I', description: 'Advanced Hub 27G x 13mm', inch: '½”', utw: '√', box: '100' },
        { gauge: '27G', code: 'HPC-27019I', description: 'Advanced Hub 27G x 19mm', inch: '¾”', utw: '√', box: '100' },
      ]
    };
  },
  computed: {
    groupedData() {
      const groups = this.tableData.reduce((acc, item) => {
        const existingGroup = acc.find(group => group.gauge === item.gauge);
        if (existingGroup) {
          existingGroup.items.push(item);
        } else {
          acc.push({ gauge: item.gauge, items: [item] });
        }
        return acc;
      }, []);
      return groups;
    }
  },
  methods: {
    displayIndex(groupIndex, itemIndex) {
      let index = 0;
      for (let i = 0; i < groupIndex; i++) {
        index += this.groupedData[i].items.length;
      }
      return index + itemIndex;
    },
    getRowClass(index) {
      return index % 2 === 0 ? 'odd-row' : 'even-row';
    }
  }
};
</script>

<style scoped>
.table-container {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  background-color: #f0f0f0;
  padding: 20px 0;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: separate;
  border-spacing: 0 5px; /* 調整行間距 */
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle; /* 使文字在上下居中 */
}
.table thead th {
  vertical-align: bottom;
  background-color: #50A7DF;
  color: white;
  border-bottom: 2px solid #dee2e6;
}
.table tbody tr {
  border-top: 5px solid white; /* 調整橫向間距為5px */
}
.table tbody td {
  border-top: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6; /* 添加直的分隔線 */
}
.table tbody td:last-child {
  border-right: none; /* 去除最後一列的右邊框 */
}
.custom-table .gauge-cell {
  background-color: #50A7DF;
  color: white;
  border-right: 1px solid #dee2e6; /* 添加直的分隔線 */
  text-align: center; /* 使Gauge文字居中 */
  vertical-align: middle; /* 使Gauge文字上下居中 */
}
.custom-table .odd-row {
  background: linear-gradient(to right, #ffffff 0%, #d9edff 100%);
}
.custom-table .even-row {
  background: linear-gradient(to left, #ffffff 0%, #d9edff 100%);
}
</style>
